<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div
        class="row"
        :class="{ 'has-error': errors.has('radioClubOrAmount') }"
      >
        <div class="col-12">
          <div class="mb-2">Zakres:</div>
          <div class="form-check form-check-inline" v-if="lang === 'pl'">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioClubOrAmount"
              id="radioClub"
              v-model="radioClubOrAmount"
              value="club"
            />
            <label class="form-check-label" for="radioClub"
              >Klub NEONAIL / Aplikacja</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioClubOrAmount"
              id="radioAmount"
              v-model="radioClubOrAmount"
              value="amount"
            />
            <label class="form-check-label" for="radioAmount">Kwota</label>
          </div>
        </div>

        <div v-if="radioClubOrAmount === 'amount'" class="form-group col-sm-3">
          <label> Od jakiej kwoty była darmowa dostawa </label>
          <input
            class="form-control"
            type="number"
            min="0"
            name="amount"
            v-model="dates.amount"
            autocomplete="off"
            v-validate="'required'"
          />
          <small v-show="errors.has('amount')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>

        <div class="col-8">
          <div class="row">
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateStart') }"
            >
              <label>
                {{ $t("start_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateStart"
                :config="optionsDate"
                name="dateStart"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateStart')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateEnd') }"
            >
              <label>
                {{ $t("end_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateEnd"
                :config="optionsDate"
                name="dateEnd"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateEnd')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="btn btn-primary ml-1" @click="set30">
                {{ $t("last_month") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set90">
                {{ $t("last_3_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set180">
                {{ $t("last_6_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="set365">
                {{ $t("last_12_months") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button class="btn btn-primary btn-sm" type="submit">
            Pobierz dane
          </button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>

      <div v-if="radioClubOrAmount === 'club'">
        <div class="row">
          <div class="col-12 col-md-5 mt-3">
            <span v-if="statistics && statistics.ordersWithClub">
              <h2>Ilość darmowej dostawy</h2>
              <p v-for="(status, i) in statistics.ordersWithClub" :key="i">
                {{ status["status"] }}:
                <strong>{{ status["counted_orders"] }}</strong>
                (
                {{
                  Math.round(
                    ((status["counted_orders"] * 100) / countedOrdersClub) * 100
                  ) / 100
                }}% )<br />
              </p>
            </span>
          </div>
          <div class="col-12 col-md-5 mt-6">
            <canvas id="free-deliveries-statistics-club-orders"></canvas>
          </div>
          <div class="col-12 col-md-5 mt-5">
            <span v-if="statistics && statistics.ordersWithClub">
              <h2>Kwota darmowej dostawy (brutto)</h2>
              <p v-for="(status, i) in statistics.ordersWithClub" :key="i">
                {{ status["status"] }}:
                <strong
                  >{{ status["total_amount"] }}{{ status["currency"] }}</strong
                >
                (
                {{
                  Math.round(
                    ((status["total_amount"] * 100) / countedAmountClub) * 100
                  ) / 100
                }}% )
                <br />
              </p>
            </span>
          </div>
          <div class="col-12 col-md-5 mt-5">
            <canvas id="free-deliveries-statistics-club-amount"></canvas>
          </div>
          <div class="col-12 col-md-5 mt-5">
            <span v-if="statistics && statistics.amountOrdersClub">
              <h2>Suma obrotu zamówień z darmową dostawą</h2>
              <p v-for="(status, i) in statistics.amountOrdersClub" :key="i">
                Obrót brutto:
                <strong
                  >{{ status.sum_total_amount_br }}{{ status.currency }}</strong
                ><br />
                Obrót netto:
                <strong
                  >{{ status.sum_total_amount_net
                  }}{{ status.currency }}</strong
                >
              </p>
            </span>
          </div>
        </div>
      </div>

      <div v-if="radioClubOrAmount === 'amount'">
        <div class="row">
          <div class="col-12 col-md-5 mt-3">
            <span v-if="statistics && statistics.ordersWithSelectedAmount">
              <h2>Ilość darmowej dostawy</h2>
              <p
                v-for="(status, i) in statistics.ordersWithSelectedAmount"
                :key="i"
              >
                {{ status["status"] }}:
                <strong>{{ status["counted_orders"] }}</strong>
                (
                {{
                  Math.round(
                    ((status["counted_orders"] * 100) / countedOrdersAmount) *
                      100
                  ) / 100
                }}% )<br />
              </p>
            </span>
          </div>
          <div class="col-12 col-md-5 mt-6">
            <canvas id="free-deliveries-statistics-amount-orders"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5 mt-5">
            <span v-if="statistics && statistics.ordersWithSelectedAmount">
              <h2>Kwota darmowej dostawy (brutto)</h2>
              <p
                v-for="(status, i) in statistics.ordersWithSelectedAmount"
                :key="i"
              >
                {{ status["status"] }}:
                <strong
                  >{{ status["total_amount"] }}{{ status["currency"] }}</strong
                >
                (
                {{
                  Math.round(
                    ((status["total_amount"] * 100) / countedAmount) * 100
                  ) / 100
                }}% )
                <br />
              </p>
            </span>
          </div>
          <div class="col-12 col-md-5 mt-5">
            <canvas id="free-deliveries-statistics-amount"></canvas>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5 mt-5">
            <span v-if="statistics && statistics.amountSelectedAmount">
              <h2>Suma obrotu zamówień z darmową dostawą</h2>
              <p
                v-for="(status, i) in statistics.amountSelectedAmount"
                :key="i"
              >
                Obrót brutto:
                <strong
                  >{{ status.sum_total_amount_br }}{{ status.currency }}</strong
                ><br />
                Obrót netto:
                <strong
                  >{{ status.sum_total_amount_net
                  }}{{ status.currency }}</strong
                >
              </p>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "FreeDeliveryStatistics",
  data() {
    return {
      dates: {
        dateStart: null,
        dateEnd: null,
        amount: 0
      },
      statistics: {},
      countedOrdersClub: 0,
      countedAmountClub: 0,
      countedOrdersAmount: 0,
      countedAmount: 0,
      radioClubOrAmount: 0,
      ordersWithClub: null,
      ordersWithSelectedAmount: null,
      ordersWithClubChart: null,
      ordersWithClubChart2: null,
      ordersWithSelectedAmountChart: null,
      ordersWithSelectedAmountChart2: null,
      lang: this.$route.params.shop_lang
    };
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  watch: {
    "dates.dateStart": function(newDateStart, oldDateStart) {
      // console.log(newDateStart);
      if (newDateStart !== oldDateStart) {
        this.clearStats();
        this.clearCharts();
      }
    },
    "dates.dateEnd": function(newDateEnd, oldDateEnd) {
      if (newDateEnd !== oldDateEnd) {
        this.clearStats();
        this.clearCharts();
      }
    },
    amount(newAmount) {
      if (newAmount !== 0) {
        this.clearCharts();
      }
    },
    radioClubOrAmount(newRadioClubOrAmount) {
      if (newRadioClubOrAmount !== undefined) {
        this.clearStats();
        this.clearCharts();
      }
    }
  },
  created() {
    this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
    this.dates.dateStart = this.$moment(this.dates.dateEnd)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    this.$emit("loading", false);
  },
  methods: {
    clearStats() {
      this.statistics !== null
        ? (this.statistics = {})
        : (this.statistics = null);
    },
    clearCharts() {
      // console.log(this.ordersWithClubChart, "1 wykres");
      this.ordersWithClubChart != null
        ? this.ordersWithClubChart.destroy()
        : (this.ordersWithClubChart = null);
      // console.log(this.ordersWithClubChart2, "2 wykres");
      this.ordersWithClubChart2 != null
        ? this.ordersWithClubChart2.destroy()
        : (this.ordersWithClubChart2 = null);
      // console.log(this.ordersWithSelectedAmountChart, "3 wykres");
      this.ordersWithSelectedAmountChart != null
        ? this.ordersWithSelectedAmountChart.destroy()
        : (this.ordersWithSelectedAmountChart = null);
      // console.log(this.ordersWithSelectedAmountChart2, "4 wykres");
      this.ordersWithSelectedAmountChart2 != null
        ? this.ordersWithSelectedAmountChart2.destroy()
        : (this.ordersWithSelectedAmountChart2 = null);
      this.$forceUpdate();
    },
    clearAmount() {
      this.amount !== 0 ? (this.amount = 0) : (this.amount = 0);
    },
    clearRadio() {
      this.radioClubOrAmount !== undefined
        ? (this.radioClubOrAmount = false)
        : (this.radioClubOrAmount = false);
    },
    set30() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    },
    set90() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(3, "months")
        .format("YYYY-MM-DD");
    },
    set180() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(6, "months")
        .format("YYYY-MM-DD");
    },
    set365() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(12, "months")
        .format("YYYY-MM-DD");
    },
    handleSubmit() {
      // console.log("handleSubmit");
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          this.$http
            .get(
              `${this.url}?dateStart=${this.dates.dateStart}&dateEnd=${this.dates.dateEnd}&amount=${this.dates.amount}`,
              this.dates
            )
            .then(resp => {
              this.statistics = resp.data;
              if (
                resp.data &&
                resp.data.ordersWithClub &&
                this.radioClubOrAmount === "club"
              ) {
                Object.keys(resp.data.ordersWithClub).map(key => {
                  this.countedOrdersClub +=
                    resp.data.ordersWithClub[key].counted_orders;
                  return resp.data.ordersWithClub[key].counted_orders;
                });
                this.ordersWithClubChart = this.createChart(
                  "free-deliveries-statistics-club-orders",
                  resp.data.ordersWithClub,
                  "counted_orders"
                );
              }
              if (
                resp.data &&
                resp.data.ordersWithClub &&
                this.radioClubOrAmount === "club"
              ) {
                Object.keys(resp.data.ordersWithClub).map(key => {
                  this.countedAmountClub +=
                    resp.data.ordersWithClub[key].total_amount;
                  return resp.data.ordersWithClub[key].total_amount;
                });
                this.ordersWithClubChart2 = this.createChart(
                  "free-deliveries-statistics-club-amount",
                  resp.data.ordersWithClub,
                  "total_amount"
                );
              }
              if (
                resp.data &&
                resp.data.ordersWithSelectedAmount &&
                this.radioClubOrAmount === "amount"
              ) {
                Object.keys(resp.data.ordersWithSelectedAmount).map(key => {
                  this.countedOrdersAmount +=
                    resp.data.ordersWithSelectedAmount[key].counted_orders;
                  return resp.data.ordersWithSelectedAmount[key].counted_orders;
                });
                this.ordersWithSelectedAmountChart = this.createChart(
                  "free-deliveries-statistics-amount-orders",
                  resp.data.ordersWithSelectedAmount,
                  "counted_orders"
                );
              }
              if (
                resp.data &&
                resp.data.ordersWithSelectedAmount &&
                this.radioClubOrAmount === "amount"
              ) {
                Object.keys(resp.data.ordersWithSelectedAmount).map(key => {
                  this.countedAmount +=
                    resp.data.ordersWithSelectedAmount[key].total_amount;
                  return resp.data.ordersWithSelectedAmount[key].total_amount;
                });
                this.ordersWithSelectedAmountChart2 = this.createChart(
                  "free-deliveries-statistics-amount",
                  resp.data.ordersWithSelectedAmount,
                  "total_amount"
                );
              }
              this.$emit("loading", false);
            })
            .catch(err => {
              console.log(err);
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    },
    createChart(chartId, data, key) {
      const labels = Object.keys(data).map(value => data[value].status);
      const datasets = Object.keys(data).map(value => data[value][key]);
      // console.log(`chartId ${chartId}; ${document.getElementById(chartId)}`);
      const myChart = new Chart(document.getElementById(chartId), {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Darmowa dostawa statystyka",
              data: datasets,
              backgroundColor: [
                "rgb(224, 187, 228)",
                "rgb(149, 125, 173)",
                "rgb(210, 145, 188)",
                "rgb(254, 200, 216)",
                "rgb(255, 223, 211)",
                "rgb(182, 211, 235)",
                "rgb(150, 153, 203)"
              ]
            }
          ]
        }
      });
      return myChart;
    }
  }
};
</script>
