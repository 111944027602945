<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Darmowa dostawa'">
        <template slot="content" slot-scope="{ setLoading }">
          <FreeDelivery :url="url" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import FreeDelivery from "@/components/Statistics/FreeDelivery";

export default {
  name: "FreeDeliveryStatistics",
  components: {
    Ibox,
    FreeDelivery
  },
  data() {
    return {
      url: `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/free-deliveries`
    };
  }
};
</script>
